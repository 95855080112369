import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FeedDiv,StyledPopup,PopupWrapper} from './styles';
import SingleFeed from '../SocialFeedsV2/singleFeedForComments';
import { ImageUrl } from '../../utils/constants';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isEmpty';
// import connect from 'react-redux/es/connect/connect';
import {connect} from 'react-redux';
import { SliderContainer, RecognitionImage } from "../SocialFeedsV2/styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Waiting from '../Waiting';
import { withTranslation } from 'react-i18next';
class ViewCommentModal extends Component{

  onClosePopup = ()=>{
    this.props.onClose();
    this.props.history.push({pathname:`/social`,state:null});
  }

  hidePopup = (id, value)=>{
    this.props.changeEditDeletePopup(id, value);
    this.props.onClose();
  }

  render(){
    const { showModal, feed, comments, inspirationPost } = this.props;
    const singleFeedData= inspirationPost? inspirationPost:feed;
    if (isEmpty(singleFeedData)) {
      return <Waiting />
    }
    let socialFeed = {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      draggable: true,
      adaptiveHeight: true,
      nextArrow: <img src={ImageUrl+"/social-feed/rightArrow.svg"} style={{width:"24px", height:"24px"}}/>,
      prevArrow: <img src={ImageUrl+"/social-feed/leftArrow.svg"} style={{width:"24px", height:"24px"}}/>,
      responsive: [
        {
          breakpoint: 1252,
          settings: {
            dots: true,
            infinite: true,
            speed: 500,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            dots: true,
            infinite: true,
            speed: 500,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
          },
        },
      ],
    };
    return(
      <StyledPopup show={showModal} width={singleFeedData && isNull(singleFeedData.post_images) ?"540px !important":"1080px !important"}>
        <div className='closeIcon' onClick={()=>this.onClosePopup()}>
          <img src={ImageUrl+"/social-feed/closeWhite.png"} />
        </div>
        <PopupWrapper width={singleFeedData && isNull(singleFeedData.post_images) ?"100%":"50%"}>
          {singleFeedData && isNull(singleFeedData.post_images)?null:<div className='imageContainer'>
            {singleFeedData && singleFeedData.post_images && singleFeedData.post_images.length>1?
              <SliderContainer
                display="flex"
                content="center"
                shadow="0px 8px 25px 0px #0D427040"
                maxHeight
                style={{margin:"auto"}}
              >
                <Slider {...socialFeed}>
                  {singleFeedData && singleFeedData.post_images && singleFeedData.post_images.map((data,index)=>
                    (
                      singleFeedData?.core_value && index===0?
                        <RecognitionImage key={index} image={data.image} margin>
                          <div className='mainContainer'>
                            <div className='image'>
                              <div className='coreImageWidth'>
                                <img src={`${ImageUrl}/${singleFeedData.recognition_image}`}/>
                              </div>
                              <div className='recognition'>
                                <span className='textRecognition'>{singleFeedData.core_value=="Happy Birthday"||singleFeedData.core_value=="Work Anniversary"?this.props.t("Celebrating"):this.props.t("Giving")}</span>&nbsp;
                                <span className='boldRecognition'>{this.props.t(singleFeedData.core_value)}</span>&nbsp;
                                <span className='textRecognition'>{this.props.t("to")}</span>&nbsp;
                                {singleFeedData.mentioned_users&& singleFeedData.mentioned_users.map((name,index)=><span key={index} className='boldRecognition'>{name.fullname}</span>)}&nbsp;
                              </div>
                            </div>
                          </div>
                        </RecognitionImage>:
                        <img src={`${ImageUrl}/${data.image}`} alt={data.body || 'placeholder'} key={index} />
                    ))}
                </Slider>
              </SliderContainer>: 
              singleFeedData.core_value?
                <RecognitionImage image={singleFeedData.bg_image} margin radius>
                  <div className='mainContainer'>
                    <div className='image'>
                      <div className='coreImageWidth'>
                        <img src={`${ImageUrl}/${singleFeedData.recognition_image}`}/>
                      </div>
                      <div className='recognition'>
                        <span className='textRecognition'>{singleFeedData.core_value=="Happy Birthday"||singleFeedData.core_value=="Work Anniversary"?this.props.t("Celebrating"):this.props.t("Giving")}</span>&nbsp;
                        <span className='boldRecognition'>{this.props.t(singleFeedData.core_value)}</span>&nbsp;
                        <span className='textRecognition'>{this.props.t("to")}</span>&nbsp;
                        {singleFeedData.mentioned_users&& singleFeedData.mentioned_users.map((name,index)=><span key={index} className='boldRecognition'>{name.fullname}</span>)}&nbsp;
                      </div>
                    </div>
                  </div>
                </RecognitionImage>:singleFeedData.image?<img src={`${ImageUrl}/${singleFeedData.image}`} alt={singleFeedData.body || 'placeholder'} />:null}
          </div>}
          <div className='commentsContainer'>
            <FeedDiv widthValue={singleFeedData.image? true : true} radius={singleFeedData && isNull(singleFeedData.post_images)}>
              <SingleFeed
                feed={singleFeedData}
                description={singleFeedData.description}
                taggedUsers={singleFeedData.shoutout_user_name}
                getCommentsForPost={this.props.getCommentsForPost}
                changeEditDeletePopup={this.hidePopup}
                editAndDelete = {this.props.editAndDelete}
                openEditAndDelete={this.props.openEditAndDelete}
                profileImage={this.props.profileImage}
                updationList="response"
                commentCount={singleFeedData.comment_count}
                history={this.props.history}
                enableTrigger={this.props.enableTrigger}
                // key={`feed-${index}-${feed.id}`}
                onChange={this.props.onChange}
                handleFeed={this.props.handleFeed}
                handleModal = {this.props.handleModal}
                feedNumber={this.props.feedNumber}
                data = {this.props.data}
                exemplifiesValues = {this.props.exemplifiesValues}
                showRecognition = {this.props.showRecognition}
                value = {this.props.value}
                id = {this.props.id}
                image = {this.props.image}
                feedNo = {this.props.feedNo}
                updateFeedsLike={this.props.updateFeedsLike}
                storedFeeds={this.props.storedFeeds}
                editPost={this.props.editPost}
                socialCallBack={this.props.socialCallBack}
                handlePhotoPostModal={this.props.handlePhotoPostModal}
                hideCommentText = {false}
                hideValuesInCommentsModel={true}
                comments={comments}
                postReply={this.props.postReply}
                replySuccess={this.props.replySuccess}
                commentId={singleFeedData.id}
                closeCommentPoup={this.props.onClose}
                isCommentModal={true}
              />
            </FeedDiv>
          </div>
        </PopupWrapper>
      </StyledPopup>
    )
  }
}

ViewCommentModal.propTypes =  {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  feed: PropTypes.object.isRequired,
  description: PropTypes.string,
  taggedUsers: PropTypes.string,
  getCommentsForPost: PropTypes.func.isRequired,
  changeEditDeletePopup: PropTypes.func.isRequired,
  editAndDelete: PropTypes.bool.isRequired,
  openEditAndDelete: PropTypes.func.isRequired,
  profileImage: PropTypes.string,
  updationList: PropTypes.string,
  commentCount: PropTypes.number,
  history: PropTypes.object,
  enableTrigger: PropTypes.bool,
  onChange: PropTypes.func,
  handleFeed: PropTypes.func,
  handleModal: PropTypes.func.isRequired,
  feedNumber : PropTypes.number,
  data: PropTypes.array.isRequired,
  exemplifiesValues : PropTypes.array,
  showRecognition : PropTypes.func,
  value : PropTypes.string,
  id : PropTypes.number,
  image : PropTypes.string,
  feedNo : PropTypes.number,
  updateFeedsLike: PropTypes.func,
  editPost: PropTypes.func,
  storedFeeds: PropTypes.array,
  socialCallBack: PropTypes.func,
  handlePhotoPostModal: PropTypes.func,
  postReplyOnComment: PropTypes.func.isRequired,
  comments: PropTypes.array,
  postReply: PropTypes.func,
  replySuccess: PropTypes.string,
  inspirationPost: PropTypes.array,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  inspirationPost: state.social.inspirationPost
});

export default connect(mapStateToProps, null)((withTranslation())(ViewCommentModal));